.mew-auth-right-content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    overflow: auto;
}

.mew-auth-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    width: 100%;
}

.mew-auth-header p {
    margin: 0;
}

.mew-auth-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.mew-auth-left-content {
    background-color: var(--brand-five);
}