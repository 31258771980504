.mew-input-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: auto;
    position: relative;

}
.mew-input-checkbox .mew-text-input {
    height: 15px;
    width: 15px;
}

.mew-input-checkbox .mew-text-input input {
    width: 15px;
    height: 15px;
}

.mew-input-checkbox label {
    margin: 0px;
}
.mew-input-checkbox label::before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
}