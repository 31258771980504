.mew-register h1 {
    color: var(--brand-one);
}

.mew-register p.mew-bullet {
    position: relative;
    padding-left: 20px;
}

.mew-register p.mew-bullet:after {
    content: '';
    position: absolute;
    background: url('../../assets/img/icons/arrow-icon.svg');
    width: 10px;
    height: 10px;
    left: 0;
    top: 6px;
    background-size: contain;
    background-repeat: no-repeat;
}

.mew-register-text-container {
    display: flex;
    margin: auto;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 20px;
}

.mew-register .mew-auth-form {
    max-width: 740px;
    width: 100%;
}

.mew-left-content {
    margin: 0px -15px;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.mew-bg-pattern {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.mew-bg-pattern span{
    position: absolute;
    display: flex;
}

.mew-bg-pattern span:first-child{
    top: -120px;
    left: 50%;
    transform: translateX(-50%);
}

.mew-bg-pattern span:nth-child(2){
    top: 20%;
    left: 40px;
}

.mew-bg-pattern span:nth-child(3){
    bottom: 0;
    right: 0;
    height: auto;
}

.mew-register-btn {
    display: flex;
    margin: auto;
    text-align: center;
    width: 360px;
    max-width: 100%;
    justify-content: center;
    background-color: var(--brand-one);
}

.mew-register-btn:hover {
    background-color: var(--brand-zero);
}

.mew-input-container .country-select {
    width: 100%;
    height: 42px;
}

.mew-input-container .country-select .input-group {
    width: 100%;
    height: 100%;
}

.mew-input-container .country-select input {
    width: 100%;
    height: 100%;
    border: 1px solid #E2E8F0;
    border-radius: 4px;
    padding: 8px 16px;
}

.mew-input-container .country-select input:disabled {
    border-color: #d9d9d9;
}
   