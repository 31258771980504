/* page layout */
.layout-container {
  margin-left: 62px;
  padding-top: 99px;
  padding-left: 27px;
  padding-bottom: 18px;
  padding-right: 30px;
  transition: 0.4s;
  background-color: #f2f3f7;
  min-height: 100vh;
  position: relative;
  z-index: 0;
}

.layout-container::before {
  content: url("../assets/img/shapes/layout-rounded.svg");
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.layout-heading {
  font-weight: var(--font-semi-bold);
  color: var(--brand-zero);
  margin-bottom: 20px;
}

/* end page layout */
/* mew table */
.mew-table img {
  height: 20px;
}

span.new {
  background-color: #ffeaa1;
  color: var(--brand-zero);
  padding: 4px 10px;
  border-radius: 6px;
  display: inline-block;
  min-width: 160px;
  text-align: center;
}

span.shipped {
  background-color: #d8f2f0;
  color: var(--brand-zero);
  padding: 4px 10px;
  border-radius: 6px;
  display: inline-block;
  min-width: 160px;
  text-align: center;
}

span.cancelled {
  background-color: #f2d8d8;
  color: #6c0000;
  padding: 4px 10px;
  border-radius: 6px;
  display: inline-block;
  min-width: 160px;
  text-align: center;
}

span.acknowledged {
  background-color: #d1f9d5;
  color: var(--brand-zero);
  padding: 4px 10px;
  border-radius: 6px;
  display: inline-block;
  min-width: 160px;
  text-align: center;
  white-space: nowrap;
}

span.awaiting-pickup {
  background-color: #ffb27a;
  color: var(--brand-zero);
  padding: 4px 10px;
  border-radius: 6px;
  display: inline-block;
  min-width: 160px;
  text-align: center;
  white-space: nowrap;
}
span.returned {
  background-color: #aad2f7;
  color: var(--brand-zero);
  padding: 4px 10px;
  border-radius: 6px;
  display: inline-block;
  min-width: 160px;
  text-align: center;
  white-space: nowrap;
}

.mew-heading-chanel-logo {
  max-height: 36px;
}

/* Input */
.ant-input.mew-input {
  height: 42px;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
  font-size: 14px;
}

.ant-input.mew-input[disabled] {
  color: rgb(0 0 0 / 57%);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

/* End Input */
/* mew-pagination */
.mew-pagination {
  text-align: center;
}

.mew-pagination .ant-pagination-prev .ant-pagination-item-link,
.mew-pagination .ant-pagination-next .ant-pagination-item-link {
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mew-pagination .ant-pagination-prev button,
.mew-pagination .ant-pagination-next button {
  border: solid 1px var(--grey-disabled);
}

.mew-pagination .ant-pagination-item-active {
  background-color: var(--brand-two);
  border-color: var(--brand-two);
  color: #fff;
}

.mew-pagination .ant-pagination-item {
  border-radius: 4px;
}

.mew-pagination .ant-pagination-options-size-changer .ant-select-selector {
  border-radius: 4px;
  border-color: var(--brand-blue-text);
  color: var(--brand-blue-text);
}

/* end mew-pagination */
/* button */
.mew-btn {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 40px;
}

.btn-outline.mew-btn:hover {
  color: var(--brand-one);
}

/* button end */

/* Accounts Page */
.mew-profile-section {
  position: relative;
  background-color: #ffffff;
  height: 100%;
  margin-left: -27px;
  margin-right: -30px;
  margin-top: 50px;
}

.mew-profile-section {
  padding-left: 27px;
  padding-right: 27px;
  padding-top: 30px;
}

.mew-profile-image {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 165px;
  height: 165px;
  padding: 8px;
  background-color: rgb(255, 255, 255);
  top: -80px;
  left: calc((100% / 12) * 7);
  overflow: hidden;
}

.mew-profile-image img {
  height: 100%;
  width: 100%;
}

.mew-profile-name h2 {
  margin-bottom: 30px;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.mew-tabs .ant-tabs-tab {
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid;
  border-color: transparent;
  transition: all 0.2s ease-in-out;
}

.mew-tabs .ant-tabs-tab.ant-tabs-tab-active {
  border-color: var(--brand-one);
}

.mew-tabs .ant-tabs-ink-bar {
  display: none;
}

.ant-tabs-top.mew-tabs > .ant-tabs-nav::before {
  bottom: -10px;
}

.ant-tabs-top.mew-tabs > .ant-tabs-nav {
  margin: 0 0 30px 0;
}

.mew-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 20px;
}

.mew-tabs .mew-tab-info {
  color: #7c7c7c;
}

.mew-accounts-tab.mew-tabs .anticon {
  padding: 3px;
  border: 1px solid var(--brand-one);
  border-radius: 2px;
}

.mew-accounts-tab.mew-tabs .anticon svg {
  fill: #1890ff;
}
.mew-accounts-tab.mew-tabs .card-input .anticon {
  border: none;
}
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: inline-block;
  color: var(--red-color);
  font-size: 14px;
  font-family: inherit;
  order: 2;
  margin-left: 4px;
}

.mew-acc-sub-card {
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-color: #d2d2d2;
  border-radius: 6px;
  overflow: hidden;
  height: 100%;
}

.mew-sub-card-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: var(--brand-five);
  padding: 20px 24px;
}

.mew-sub-card-header h3 {
  color: var(--brand-one);
  font-size: 24px;
}

.mew-sub-card-price p {
  margin: 0;
  color: var(--brand-one);
  font-size: 30px;
  font-weight: 600;
  line-height: normal;
}

.mew-sub-card-price p:last-child {
  font-size: 16px;
}

.mew-acc-sub-content ul {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  flex-direction: row;
  list-style: none;
  padding: 0;
}

.mew-acc-sub-content ul li {
  width: calc(50% - 5px);
  font-size: 14px;
  color: var(--brand-zero);
}

.mew-acc-sub-content {
  padding: 0px 20px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.mew-acc-sub-content span {
  margin-right: 8px;
}

.mew-acc-sub-content span img {
  width: 12px;
  height: auto;
}

.mew-acc-sub-card.mew-plan-renewal-date {
  padding: 20px 24px;
}

.mew-acc-sub-card.mew-plan-renewal-date .mew-acc-sub-content {
  padding: 0px;
}

.mew-auto-renewal {
  display: flex;
  margin-top: auto;
  align-items: center;
  border-top: 1px solid var(--brand-five);
  padding-top: 10px;
}

.mew-acc-sub-card p {
  margin: 0px;
}

.mew-acc-sub-card.mew-plan-renewal-date p:first-child {
  color: #7c7c7c;
  margin-right: 5px;
}

.mew-auto-renewal p {
  font-size: 16px;
  font-weight: 600;
}

.mew-acc-sub-card.mew-plan-renewal-date .mew-acc-sub-content h2 {
  color: var(--brand-zero);
  font-size: 18px;
  font-weight: 600;
}

.mew-acc-sub-card.mew-plan-renewal-date .mew-acc-sub-content h4 {
  color: #7c7c7c;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}

@media only screen and (max-width: 1024px) {
  .mew-acc-sub-content ul li {
    width: 100%;
  }
}

.mew-pass-card {
  padding: 20px 24px;
  border: 1px solid;
  border-color: #d2d2d2;
  border-radius: 6px;
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
}

.mew-pass-card ul {
  padding: 0px;
  list-style: none;
  margin: 0px;
}

.mew-pass-card ul li span img {
  width: 14px;
  margin-right: 8px;
}

.mew-pass-card .ant-form {
  width: 100%;
}

.mew-pass-card .ant-form .ant-form-item {
  margin-bottom: 10px;
}

.mew-pass-card ul li {
  padding-bottom: 8px;
}

.mew-acc-settings-item {
  width: calc(100% / 3);
}

.mew-acc-settings-item h3 {
  margin: 0;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
}

.mew-acc-settings-item p {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

/* table */
.ant-table-thead > tr > th {
  padding: 6px 15px;
  background-color: var(--table-head-color);
  font-weight: var(--font-semi-bold);
}

.ant-table-thead > tr > th::before {
  display: none;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  border-radius: 0;
}

td,
th {
  background-color: #fff;
}

td {
  padding: 16px;
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid var(--brand-five);
}

.ant-table-tbody > tr:last-child > td {
  border-bottom: none;
}

.ant-table-cell .product-cell {
  display: flex;
  align-items: center;
}

.ant-table-cell .product-cell img {
  margin-right: 15px;
}

.product-small-img {
  width: 42px;
  height: 42px;
  object-fit: contain;
}

@media (max-width: 992px) {
  .ant-table-thead > tr > th {
    padding: 8px;
  }

  .ant-table-tbody > tr > td {
    padding: 8px;
  }
}

/* end table */
/* header */
.sidemenu-icon {
  cursor: pointer;
}

/*end header */
/* dashboard */
.bar-graph-container {
  padding-left: 40px;
  position: relative;
}

.bar-graph-container .left-title {
  position: absolute;
  top: 50%;
  transform: rotate(-90deg);
  left: 0;
}

.line-break {
  height: 4px;
  width: 100%;
  background-color: var(--brand-four);
  margin-bottom: 36px;
  margin-top: 16px;
}

.progress-stock-color {
  width: 20px;
  height: 20px;
  background: rgb(16, 138, 212);
  background: linear-gradient(
    176deg,
    rgba(16, 138, 212, 1) 0%,
    rgb(52 177 224) 100%,
    rgba(53, 179, 224, 1) 100%
  );
  border-radius: 4px;
}

.progress-stock-color.outoff {
  background: #96dbe4;
}

.progress-bar-container .number {
  font-size: 24px;
  line-height: 22px;
  margin-top: 4px;
}

.mew-topselling-dashboard{
   overflow: auto;
}
@media (max-width:992px){
  .mew-topselling-dashboard{
    height: 100% !important;
  }
}
/* end dashboard */

/* Notification */
.mew-notify-tab-title {
  font-size: 16px;
  font-weight: var(--font-regular);
  color: var(--brand-zero);
  margin: 0 0 16px 0;
}

.mew-notify-btn {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  font-weight: var(--font-semi-bold);
  border-radius: 4px;
}

.mew-notify-btn-secondary {
  padding: 6px 12px;
  display: flex;
  font-weight: var(--font-semi-bold);
  align-items: center;
  gap: 5px;
  border-radius: 4px;
}

.mew-notify-tabs .ant-tabs-tab {
  padding: 0;
}

.mew-notify-tabs .ant-tabs-nav {
  margin: 0;
}

.mew-notify-tabs .ant-tabs-nav .ant-tabs-nav-list {
  margin-bottom: 10px;
}

.mew-notify-tabs .ant-tabs-ink-bar {
  display: none;
}

.mew-notify-tabs .ant-tabs-nav::before {
  border-bottom: 1px solid var(--brand-five);
}

.mew-notify-tabs .anticon {
  padding: 3px;
  border: 1px solid var(--brand-one);
  border-radius: 2px;
}

.mew-notify-tabs .anticon svg {
  fill: var(--brand-one);
}

.mew-notify-tabs.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more {
  margin-bottom: 12px;
}

/* Notification */

/* start analytics-page */
.mew-analytics-page .mew-refresh-icon {
  background-color: var(--brand-one);
  padding: 10px;
  border-radius: 4px;
}

.mew-analytics-page .mew-users {
  background-color: var(--brand-one);
  padding: 10px 25px;
  border-radius: 4px;
  display: inline-block;
  font-size: 18px;
  font-weight: var(--font-semi-bold);
  color: #fff;
  margin-top: 16px;
}

.mew-analytics-page .mew-heading-container {
  border-bottom: solid 1px #ebf0ff;
  gap: 4px;
  padding-bottom: 4px;
}

.mew-analytics-page .mew-progress-main-container {
  height: 270px;
  overflow-y: auto;
}

.mew-analytics-page .mew-grow-card {
  height: calc(100% - 20px);
}

/* end analytics-page */

/*start marketplace*/
.mew-marketplace-heading {
  color: var(--brand-zero);
  font-weight: var(--font-semi-bold);
  margin: 0;
  line-height: 32px;
}

.mew-marketplace {
  padding-bottom: 10px;
  border-bottom: 1px solid #ebf0ff;
  margin-bottom: 10px;
}

.mew-marketplace-title {
  font-size: 18px;
  font-weight: var(--font-bold);
  color: var(--brand-zero);
  margin: 0;
}

.mew-marketplace-item-container {
  flex-wrap: wrap;
  gap: 10px;
}

.mew-marketplace-container.row {
  row-gap: 23px;
}

.mew-marketplace-card-container {
  row-gap: 20px;
}

@media (max-width: 720px) {
  .mew-marketplace-item-container {
    flex-wrap: wrap;
  }
}

/*end marketplace*/
/* OrderDashboard-page */
.OrderDashboard-page .mew-filter-container {
  gap: 20px;
}

/* OrderDashboard-page */
/* OrderDetail-page */
.mew-order-img {
  height: 24px;
  object-fit: contain;
}
.mew-tracking-order-details{
  gap: 10px;
}
.mew-add-tracking-input {
  margin-bottom: 0px;
}
.mew-order-cancel-btn {
  background-color: #e41e1e;
  border-color: #e41e1e;
}

.mew-order-cancel-btn .btn-outline.mew-btn:hover {
  background-color: #ef4444 !important;
}

.order-detail-page .ant-checkbox-wrapper {
  min-width: 200px;
}

.order-detail-page .order-id-badge {
  border: 2px solid var(--brand-one);
  border-radius: 4px;
  padding: 5px 12px;
  font-size: 16px;
  line-height: 20px;
  font-weight: var(--font-bold);
  color: var(--brand-one);
}

.order-detail-page .mew-input-checkbox {
  margin-right: 20px;
}

.order-detail-page .mew-input-checkbox label {
  white-space: nowrap;
}

.mew-connect-card-item {
  width: calc((100% / 5) - 8px);
}

@media only screen and (max-width: 1199px) {
  .mew-connect-card-item {
    width: calc((100% / 4) - 8px);
  }
}

@media only screen and (max-width: 1024px) {
  .mew-connect-card-item {
    width: calc((100% / 3) - 8px);
  }
}

.order-detail-page .order-detail-table tbody tr:last-child td {
  font-weight: var(--font-semi-bold);
}

.order-detail-page .tab-link {
  line-height: 1;
  margin-top: 2px;
}

.order-detail-page .box-detail-wrapper {
  column-gap: 10px;
}

.order-detail-page .box-detail-wrapper .mew-select {
  max-width: 140px;
}

.order-detail-page .box-detail-wrapper .mew-input {
  flex: 1;
}

.order-add-shipment-input-width {
  width: 250px !important;
  position: relative;
  max-width: 100%;
}

.order-add-shipment-label-width .ant-form-item-label {
  width: 133px;
}

.dynamic-delete-button {
  position: absolute;
  top: 12px;
  right: -20px;
  color: red;
}
.order-add-shipment-label-width .ant-form-horizontal .ant-form-item-label {
  width: 133px;
}
.order-add-shipment-form .mew-text-input {
  width: 250px;
  height: 100%;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  padding: 8px 16px;
}
.mew-add-tracking-btn.ant-form-item {
  margin-bottom: 12px;
}
.mew-order-ship-list {
  gap: 10px;
}
.mew-dropdown-full-width .ant-select-dropdown{
   min-width: 100% !important;
}
.ant-spin-nested-loading > div > .ant-spin.mew-shipping-charge-loader {
  padding-bottom: 50px;
  top:-160px;
}
/* OrderDetaild-page */
/*amazon-dashboard*/

.mew-amazon-sales-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 55px;
}

.mew-amazon-sales-card {
  width: 33%;
  padding-bottom: 11px;
}

.mew-amz-sales-text .number {
  font-size: 20px;
  white-space: nowrap;
}
.mew-amz-sales-text .text {
  white-space: nowrap;
}

@media (min-width: 992px) {
  .mew-amazon-sales-card {
    width: 40%;
  }

  .mew-amazon-sales-details {
    flex-direction: column;
  }

  .sidemenu:hover + .layout-container .mew-amazon-sales-details,
  .sidemenu.open + .layout-container .mew-amazon-sales-details {
    flex-direction: column;
  }

  .sidemenu:hover + .layout-container .mew-amazon-sales-card,
  .sidemenu.open + .layout-container .mew-amazon-sales-card {
    padding-bottom: 20px;
  }
}

@media (min-width: 1292px) {
  .mew-amazon-sales-details {
    flex-direction: row;
  }

  .mew-amazon-sales-card {
    padding-bottom: 60px;
  }
  .mew-amz-sales-text .number {
    font-size: 22px;
  }
}

@media (min-width: 1400px) {
  .sidemenu:hover + .layout-container .mew-amazon-sales-details,
  .sidemenu.open + .layout-container .mew-amazon-sales-details {
    flex-direction: row;
  }

  .sidemenu:hover + .layout-container .mew-amazon-sales-card,
  .sidemenu.open + .layout-container .mew-amazon-sales-card {
    padding-bottom: 60px;
  }
  .mew-amz-sales-text .number {
    font-size: 24px;
  }
}

.mew-amazon-table {
  box-shadow: none;
  overflow-x: auto;
}

.mew-summary-data {
  display: flex;
  justify-content: flex-end;
}

.mew-summary-data .title {
  margin: 0;
}

/*amazon-dashboard*/
/* amazon-suggest-word-page */
.amazon-suggest-word-page .mew-table-without-ant thead th:first-child {
  width: 8%;
}

.amazon-suggest-word-page .mew-table-without-ant td {
  vertical-align: top;
}

.amazon-suggest-word-page .mew-collapse .ant-collapse-content-box span {
  border: solid 1px var(--brand-five);
  padding: 4px;
  margin-right: 14px;
  border-radius: 6px;
  display: inline-block;
  margin-bottom: 10px;
}

.mew-suggest-btns {
  margin-top: 10px;
}

@media (max-width: 1024px) {
  .amazon-suggest-word-page .mew-filter-container {
    flex-direction: row;
  }
}

/* end amazon-suggest-word-page */
/*faq section*/
.mew-faq-section-title {
  font-weight: var(--font-semi-bold);
  color: var(--brand-zero);
}

/* product add card */
.mew-add-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ebf0ff;
  padding-bottom: 15px;
}

.mew-add-header h5 {
  margin: 0px;
}

.mew-detail-item {
  display: flex;
  flex-direction: column;
}

textarea {
  width: 100%;
  max-height: 100px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  padding: 8px 16px;
  resize: none;
}

textarea:focus-visible,
textarea:focus {
  outline: 0;
  border-color: var(--brand-one) !important;
  box-shadow: none;
}

.mew-product-details {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;
}

.mew-product-add-details{
  overflow: visible;
}

/* end product add card */
/* inventory-import-export-page */
.inventory-import-export-page .mew-inventory-step {
  padding: 15px 10px;
  border: solid 1px var(--brand-five);
  min-width: 290px;
}

.inventory-import-export-page .mew-inventory-step span {
  background-color: var(--brand-one);
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
}

.mew-inventory-import-export-table span.done {
  background-color: #d1f9d5;
  color: var(--brand-zero);
  padding: 4px 8px;
  border-radius: 6px;
  display: block;
  text-align: center;
}

.mew-inventory-import-export-table span.process {
  background-color: #b1ebf2;
  color: var(--brand-zero);
  padding: 4px 4px;
  border-radius: 6px;
  display: block;
  text-align: center;
}
.mew-inventory-import-export-table .ant-table-tbody > tr > td {
  white-space: nowrap;
}
/* end inventory-import-export-page */

/*product-catalogue*/

.mew-product-catalogue-tabs .ant-tabs-ink-bar {
  display: none;
}

.mew-marketplace-date-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mew-marketplace-date-filter .mew-filter-title {
  font-weight: var(--font-regular);
  margin-bottom: 0;
  margin-right: 11px;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.mew-marketplace-date-filter .mew-date-filter {
  margin-right: 24px;
}

.mew-marketplace-date-filter .mew-date-filter.ant-picker {
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
}

.mew-marketplace-date-filter .mew-date-filter .ant-picker-input > input {
  width: 90px;
  font-size: 16px;
  font-weight: var(--font-regular);
}

.mew-marketplace-date-filter
  .mew-date-filter
  .ant-picker-input
  > input::placeholder {
  color: #c7c7c7 !important;
}

.mew-marketplace-date-filter .mew-date-filter .ant-picker-clear {
  display: none;
}

.mew-product-catalogue-tabs .ant-tabs-nav {
  flex-direction: column;
  align-items: flex-start;
  width: 250px;
  overflow: auto;
  margin-top: 10px;
  margin-right: 24px;
  max-height: calc(52px * 10);
}

.mew-product-catalogue-tabs.ant-tabs-left
  > .ant-tabs-nav
  .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after {
  opacity: 0;
}

.mew-product-catalogue-tabs.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  order: 1;
  overflow: visible;
  scroll-behavior: smooth;
  padding-right: 10px;
}

.mew-product-catalogue-tabs .ant-tabs-nav::-webkit-scrollbar {
  width: 5px;
}

.mew-product-catalogue-tabs .ant-tabs-nav::-webkit-scrollbar-track {
  background: #f1f1f1;
  margin-top: 40px;
  border-radius: 5px;
}

.mew-product-catalogue-tabs .ant-tabs-nav::-webkit-scrollbar-thumb {
  background: #a9a9a9;
  border-radius: 5px;
}

.mew-product-catalogue-tabs .ant-tabs-nav h6 {
  padding: 10px 10px 10px 0;
  margin: 0px;
  color: var(--brand-zero);
}

.mew-product-catalogue-tabs.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
  border-top: 1px solid var(--brand-five);
  margin: 0;
  padding: 5px 0;
}

.mew-product-catalogue-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #ffffff;
  width: 100%;
  text-align: left;
  background-color: var(--brand-one);
  border-radius: 6px;
}

.mew-product-catalogue-tabs .ant-tabs-tab-btn {
  padding: 10px 24px 10px 10px;
  white-space: normal;
  text-align: left;
}

.mew-product-catalogue-tabs span.active {
  background-color: #d1f9d5;
  color: #0f891c;
  padding: 4px 20px;
  border-radius: 6px;
  display: block;
  text-align: center;
}

.mew-product-catalogue-tabs span.inactive {
  background-color: #ffdf8b;
  color: #9a730d;
  padding: 4px 14px;
  border-radius: 6px;
  display: block;
  text-align: center;
}

.mew-product-import-export-table span.done {
  background-color: #d1f9d5;
  color: var(--brand-zero);
  padding: 4px 8px;
  border-radius: 6px;
  display: block;
  text-align: center;
}

.mew-product-import-export-table span.process {
  background-color: #b1ebf2;
  color: var(--brand-zero);
  padding: 4px 4px;
  border-radius: 6px;
  display: block;
  text-align: center;
}

.mew-table-btn {
  padding: 4px 8px;
}

.mew-product-catalogue-tabs.ant-tabs-left > .ant-tabs-content-holder {
  border: none;
}

.mew-table-card a {
  color: var(--brand-zero);
}

.mew-product-catalogue-tabs.ant-tabs-left
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane {
  padding: 20px 0;
}

.mew-product-catalogue-tabs.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations {
  display: none;
  order: 2;
}

.mew-product-catalogue-tabs.ant-tabs > .ant-tabs-nav .ant-tabs-extra-content {
  position: sticky;
  top: 0px;
  z-index: 1;
  order: 0;
  width: 100%;
  background: #fff;
}

@media (min-width: 1025px) {
  .mew-product-tabs-icon {
    display: none;
  }
}

@media (max-width: 1024px) {
  .mew-product-tabs-icon .mew-tab-icon {
    width: 16px;
    height: 17px;
  }

  .mew-product-catalogue-tabs.hide .ant-tabs-nav {
    margin-right: 0px;
    width: 0px;
    overflow: hidden;
    z-index: 1000;
  }

  .mew-product-catalogue-tabs.hide.ant-tabs-left > .ant-tabs-nav {
    min-width: 0px;
  }

  .mew-product-catalogue-tabs.ant-tabs {
    position: relative;
  }

  .mew-product-catalogue-tabs.show .ant-tabs-nav {
    width: 250px;
    position: absolute;
    background-color: #ffffff;
    box-shadow: 4px 6px rgba(0, 0, 0, 0.05);
    margin-right: 0px;
    z-index: 1000;
    padding: 10px 8px 10px 10px;
    top: 19px;
    left: 1px;
    transition: 0.1s ease-in;
  }

  .mew-product-catalogue-tabs.ant-tabs > .ant-tabs-nav .ant-tabs-extra-content {
    top: -12px;
  }

  .mew-product-catalogue-tabs .anticon {
    display: none;
  }

  .mew-product-catalogue-tabs.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    overflow: hidden;
  }
}

.mew-product-catelogue-header {
  flex-wrap: wrap;
  row-gap: 10px;
}

/*product-catalogue*/

/*product-backup-detail*/
.mew-product-backup-title {
  border-bottom: 1px solid #ebf0ff;
  padding-bottom: 15px;
  margin-bottom: 20px;
}

.mew-product-backup-title .mew-title {
  color: var(--brand-zero);
  font-weight: var(--font-medium);
  font-size: 20px;
  margin: 0px;
}

.mew-product-backup-img-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.mew-product-backup-img-container .mew-product-img {
  border: 1px solid var(--brand-five);
  width: calc((100% / 3) - 10px);
}

.mew-product-backup-img-container .mew-img {
  width: 100%;
  height: 100%;
}

.mew-product-dimensions-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.mew-product-dimensions-container {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.mew-product-dimensions-container .mew-title {
  margin: 0;
  font-weight: var(--font-regular);
  color: var(--brand-zero);
}

.mew-product-dimension-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 20px;
}

.mew-product-dimension-details .mew-label {
  margin: 0;
  font-weight: var(--font-regular);
  color: #000000;
}

.mew-product-dimension-details .mew-detail {
  font-weight: var(--font-medium);
  padding-left: 7px;
}

.mew-product-category {
  display: grid;
  row-gap: 15px;
  column-gap: 15px;
  grid-template-columns: auto 4fr;
}

.mew-product-category .mew-label {
  color: var(--brand-zero);
  font-weight: var(--font-regular);
  margin: 0px;
}

.mew-product-category .mew-detail {
  color: var(--brand-zero);
  font-weight: var(--font-medium);
  margin: 0px;
}

.mew-product-bullets {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
}

.mew-product-item {
  padding: 10px;
  border-radius: 6px;
  border: 1px solid var(--brand-one);
  background-color: var(--text-light);
}

.mew-product-item .mew-detail {
  margin: 0;
  color: var(--brand-one);
  font-weight: var(--font-regular);
}

.mew-product-search-list {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.mew-product-search-list .mew-search-item {
  border-radius: 6px;
  padding: 10px;
  border: 1px solid var(--brand-one);
  background-color: var(--text-light);
  cursor: pointer;
}

.mew-product-search-list .mew-search-item .mew-detail {
  margin: 0;
  color: var(--brand-one);
  font-weight: var(--font-regular);
  line-height: 17px;
}

.mew-product-search-list .mew-search-item .mew-img {
  width: 15px;
  height: 15px;
}

.mew-product-backup-details-tab.ant-tabs .ant-tabs-nav {
  color: var(--brand-one);
  font-weight: var(--font-medium);
}

.mew-product-backup-details-tab .ant-tabs-nav-list .ant-tabs-tab {
  padding: 9px 17px;
}

.mew-product-backup-details-tab .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 15px;
}

.mew-product-backup-details-tab
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: var(--brand-one);
  font-weight: var(--font-medium);
}

.mew-product-backup-details-tab .ant-tabs-ink-bar {
  background-color: var(--brand-one);
}

.mew-product-backup-details-tab.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 1px solid #dadada;
}

.mew-product-category-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 20px;
  width: 100%;
}

.mew-product-category-items .mew-detail {
  position: relative;
  display: flex;
  width: auto;
}

.mew-product-category-items .mew-detail:not(:last-child):after {
  content: ">";
  position: absolute;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
}

/*product-backup-detail*/

/* Product import export */
.mew-category-listing {
  width: 100%;
  display: flex;
  border-radius: 8px;
  overflow: auto;
  border: 1px solid var(--brand-five);
  margin-bottom: 16px;
}

.mew-category-listing .mew-category-col {
  min-width: 220px;
  width: calc(100% / 5);
  border-right: 1px solid var(--brand-five);
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px;
  max-height: calc(44px * 10.2);
  overflow: auto;
}

.mew-category-listing .mew-category-col:last-child {
  border-right: none;
}

.mew-category-listing .mew-category-col .mew-btn {
  height: 24px;
  font-size: 12px;
  padding: 0 8px;
}

.mew-category-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 6px 10px;
  max-height: 34px;
  align-items: center;
}

.mew-category-item a {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}

.mew-category-item.active,
.mew-category-item.selected,
.mew-category-item:hover {
  background: var(--brand-five);
}

.mew-category-breadcrumb {
  display: flex;
  gap: 10px;
  position: relative;
  margin: 16px 0px;
}

.mew-category-breadcrumb a {
  position: relative;
  padding-right: 20px;
}

.mew-category-breadcrumb a:after {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  background: url("../assets/img/icons/rightArrow-icon.svg");
  background-repeat: no-repeat;
  background-size: contain;
  right: -8px;
  top: 50%;
  transform: translateY(-50%);
}

.mew-category-breadcrumb a:last-child:after {
  display: none;
}

.mew-table-btn {
  padding: 4px 8px;
}

.mew-login-bg {
  background-image: url("../assets/img/auth-bg-img.jpg");
  background-size: cover;
}

.mew-register-bg {
  background-image: url("../assets/img/auth-bg-img-2.jpg");
  background-size: cover;
  background-color: var(--brand-five);
}

.ant-steps-icon svg {
  vertical-align: unset;
}

/*dashboard*/
.mew-sales-range-select {
  column-gap: 17px;
  row-gap: 10px;
}

.mew-sales-range-inputs {
  gap: 17px;
}

@media (max-width: 780px) {
  .mew-sales-range-inputs {
    gap: 6px;
  }

  .mew-sales-range-select .ant-select.mew-select {
    max-width: 160px;
  }
}

@media (max-width: 680px) {
  .mew-sales-range-select .ant-select.mew-select {
    max-width: 140px;
  }
}

/*product-add*/

.mew-product-identifier-row .ant-col:nth-child(1) {
  width: 30%;
}

.mew-product-identifier-row .ant-col:nth-child(2) {
  width: 70%;
}

@media (max-width: 1225px) {
  .sidemenu:hover
    + .layout-container
    .mew-product-identifier-row
    .ant-col:nth-child(1),
  .sidemenu.open
    + .layout-container
    .mew-product-identifier-row
    .ant-col:nth-child(1) {
    width: 30%;
  }

  .sidemenu:hover
    + .layout-container
    .mew-product-identifier-row
    .ant-col:nth-child(2),
  .sidemenu.open
    + .layout-container
    .mew-product-identifier-row
    .ant-col:nth-child(2) {
    width: 70%;
  }
}

@media (max-width: 991px) {
  .sidemenu:hover
    + .layout-container
    .mew-product-identifier-row
    .ant-col:nth-child(1),
  .sidemenu.open
    + .layout-container
    .mew-product-identifier-row
    .ant-col:nth-child(1) {
    width: 100%;
  }

  .sidemenu:hover
    + .layout-container
    .mew-product-identifier-row
    .ant-col:nth-child(2),
  .sidemenu.open
    + .layout-container
    .mew-product-identifier-row
    .ant-col:nth-child(2) {
    width: 100%;
  }
}

/*product-add*/

/* google-analytics*/
.mew-google-analytics-progress-section {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.mew-google-analytics-progress-card {
  width: calc(25% - 12px);
  box-sizing: border-box;
}

@media (max-width: 1411px) {
  .sidemenu:hover + .layout-container .mew-google-analytics-progress-card,
  .sidemenu.open + .layout-container .mew-google-analytics-progress-card {
    width: calc(50% - 8px);
  }

  .sidemenu:hover + .layout-container .mew-progress-title .third-title,
  .sidemenu.open + .layout-container .mew-progress-title .third-title {
    width: 22%;
  }

  .sidemenu:hover + .layout-container .mew-progress-title .second-title,
  .sidemenu.open + .layout-container .mew-progress-title .second-title {
    width: 30%;
  }
}

@media (max-width: 1223px) {
  .mew-google-analytics-progress-card {
    width: calc(50% - 8px);
  }

  .mew-progress-title .third-title {
    width: 22%;
  }

  .mew-progress-title .second-title {
    width: 30%;
  }
}

@media (max-width: 933px) {
  .sidemenu:hover + .layout-container .mew-google-analytics-progress-card,
  .sidemenu.open + .layout-container .mew-google-analytics-progress-card {
    width: 100%;
  }

  .mew-progress-title .third-title {
    width: 30%;
  }

  .mew-progress-title .second-title {
    width: 20%;
  }
}

@media (max-width: 700px) {
  .mew-google-analytics-progress-card {
    width: 100%;
  }
}
.mew-bar-axis-label {
  color: #7c7c7c;
}
/* google-analytics*/
/*report-marketplace-sales*/
.mew-report-marketplace-filter {
  gap: 20px;
}

.mew-report-marketplace-search {
  row-gap: 10px;
  column-gap: 20px;
}

.mew-report-marketplace-filter .mew-select {
  width: 140px;
}

.mew-report-marketplace-table .mew-table-btn {
  font-size: 14px;
  font-weight: var(--font-semi-bold);
  color: var(--text-light);
  display: inline-flex;
  align-items: center;
}

.mew-report-marketplace-table .mew-table-btn img {
  height: 14px;
  margin-left: 11px;
  margin-right: 7px;
}

/*report-marketplace-sales*/

.ant-message .anticon {
  top: -3px !important;
}

/*order-detail*/
.mew-order-img {
  height: 24px;
  object-fit: contain;
}
/*order-detail*/
