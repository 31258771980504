.mew-input-container {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.mew-input-container label span {
    color: var(--red-color);
}

.mew-input-container .mew-text-input {
    height: 42px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.mew-input-container .mew-text-input span {
    height: 100%;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0px;
}

.mew-input-container .mew-text-input button {
    height: 100%;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0px;
    background-color: transparent;
    border: none;
}

.mew-input-container .mew-text-input input {
    width: 100%;
    height: 100%;
    border: 1px solid #E2E8F0;
    border-radius: 4px;
    padding: 8px 16px;
}

.mew-input-container .mew-text-input.mew-left-icon input {
    padding-left: 48px;
}

.mew-input-container .mew-text-input.mew-right-icon input {
    padding-right: 48px;
}

.mew-input-container .mew-validationMessage span {
    color: #ff4d4f;
    width: 100%;
    display: flex;
    word-break: break-all;
}

