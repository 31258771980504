

:root {
  --brand-zero: #0B2446;
  --brand-one: #108AD4;
  --brand-two: #5DB1DB;
  --brand-three: #78C8E1;
  --brand-four: #96DBE4;
  --brand-five: #D8F2F0;
  --text-color: #515151;
  --text-dark: #0B2446;
  --text-light: #FFFFFF;
  --brand-blue-text:#7FA2B8;
  --menu-link: #386C8C;
  --red-color: #DB5D5D;
  --grey-text: #767676;
  --grey-one:#7C7C7C;
  --grey-disabled:#E3E3E3;
  --Grey-border-color:#D2D2D2;
  --table-head-color:#ECECEC;
  --font-regular:400;
  --font-medium:500;
  --font-semi-bold: 600;
  --font-bold: 700;
  --transition: .4s;
}

.brand-zero{
  color: var(--brand-zero) ;
}
.grey-one{
  color: var(--grey-one);
}
.font-weight-bold{
  font-weight: var(--font-bold);
}
.font-weight-medium{
  font-weight: var(--font-medium);
}
.font-weight-semi{
  font-weight: var(--font-semi-bold);
}
.font-weight-regular{
  font-weight: var(--font-regular);
}

h1, .h1{
    font-size: 30px;
    font-weight: 600;
  }
  h2, .h2{
    font-size: 24px;
    font-weight: var(--font-bold);
  }
  h3, .h3{
    font-size: 18px;
    font-weight: var(--font-semi-bold);
    margin-bottom: 1rem;
  }
  h4, .h4{
    font-size: 16px;
    font-weight: 700;
  }
  .text-small{
    font-size: 12px;
  }
  @media (min-width: 481px){
    .container{
        max-width: 100% !important;
    }
  }
  @media (min-width: 768px){
    .container{
        max-width: 100% !important;
    }
  }
  @media (min-width: 1280px){
    .container{
        max-width: 100% !important;
    }
  }
  @media (min-width: 1440px){
    .container{
        max-width: 1700px !important;
    }
  }

.text-link{
    color: var(--brand-one);
    font-weight: var(--font-regular);
    display: flex;
    align-items: center;
}
.menu-link{
  color: var(--menu-link);
  font-weight: var(--font-semi-bold);
}
table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  text-align: left;
  padding: 8px;
  background-color: #F7F7F7;
}

tr:nth-child(even) td {
  background-color: rgb(247, 247, 247, 0.2);
}

.text-green {
  color: #19A759;
}

.border-top-line {
  border-top: solid 1px rgba(0, 0, 0, 0.2);
}

.ant-dropdown-menu {
  border-radius: 6px !important;
  border: solid 1px #E2E2E2;
  background-color: #fff !important;
  padding: 20px 24px !important;
  box-shadow: none !important;
  width: auto !important;
}
/* end page layout */

/* Input */
input {
  font-size: 16px;
}

input:focus-visible,
input:focus,
.form-control:focus {
  outline: 0;
  border-color: var(--brand-one) !important;
  box-shadow: none;
}

input::placeholder {
  color: #A0AEC0 !important;
}

input[disabled] , 
.form-control:disabled{
  color: rgb(0 0 0 / 57%);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

/* Select */
.ant-select.mew-select {
  width: 100%;
  height: 42px;
}

.ant-select.mew-select .ant-select-selector {
  width: 100%;
  height: 100%;
  border: 1px solid #E2E8F0;
  border-radius: 4px;
  padding: 8px 16px;
}

.mew-select .ant-select-arrow {
  width: 18px;
  height: 18px;
}

.mew-select .ant-select-arrow .anticon{
  display: none;
}

.mew-select span.ant-select-arrow {
  top: calc(50% - 3px);
}

.mew-select span.ant-select-arrow:after {
  content: '';
  position: absolute;
  width: 8px;
  height: 100%;
  left: 6px;
  top: 1px;
  background: url('../assets/img/icons/rightArrow-icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain ;
  transform: rotate(90deg);
  transition: all 0.4s ease-in-out;
}

.mew-select.ant-select-open span.ant-select-arrow:after {
  transform: rotate(-90deg);
}

.ant-select-dropdown {
  border-radius: 6px;
  border: 1px solid var(--Grey-border-color);
  box-shadow: 1px 3px 6px 0px rgba(111, 111, 111, 0.25);
  margin-top: -4px;
  padding: 4px;
  min-width: 130px !important;
}
.ant-select-dropdown:not(.mew-dropdown-w-fill) {
  width:  auto !important;
}
.ant-dropdown-menu-item:hover{
  background-color: transparent;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: var(--brand-five);
}

.mew-select.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .mew-select.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  margin-top: -3px;
}

/* button */
.btn{
  font-weight: var(--font-semi-bold);
}
.btn-primary,
.ant-btn-primary {
  background-color: var(--brand-one);
}

.btn-outline {
  /* background-color: #fff; */
  border-color: var(--brand-one);
  color: var(--brand-one);
}

.btn-primary.disabled, .btn-primary:disabled,
.ant-btn-primary:disabled {
  background-color: var(--brand-two);
  border-color: var(--brand-two);
}
.mew-border-bottom{
  border-bottom: solid 1px #EBF0FF;
}
.mew-border-bottom.green{
  border-bottom: solid 1px var(--brand-five);
}
/* datepicker */
.mew-datepicker{
  width: 100%;
  height: 100%;
  border: 1px solid #E2E8F0;
  border-radius: 4px;
  padding: 8px 16px;
}
.ant-picker-cell-inner{
  display: flex !important;
  margin: 2px;
  justify-content: center;
}
.mew-table-without-ant thead th{
  background-color: #ECECEC;
  font-weight: var(--font-semi-bold);
}
.mew-table-without-ant tbody tr td{
 position: relative;
 padding: 8px 12px;
}
.mew-table-without-ant tbody tr td:after{
  content: '';
  position: absolute;
  right: 0;
  top: 10px;
  width: 1px;
  height: 20px;
  background-color: var(--brand-five);
}
.mew-table-without-ant tbody tr td:last-child:after{
  display: none;
}
.mew-collapse {
  border: none;
  background-color: #fff;
}
.mew-collapse .ant-collapse-header{
  padding: 0 !important;
}
.mew-collapse .ant-collapse-item {
  border-bottom: none;
  position: relative;
}
.mew-collapse .ant-collapse-expand-icon {
  display: none;
}
.mew-collapse .ant-collapse-item .ant-collapse-header::after{
  content: '';
  position: absolute;
  background-image: url('../assets/img/icons/down.svg');
  width: 20px;
  height: 20px;
  object-fit: contain;
  background-repeat: no-repeat;
  background-position: center;
  right: 10px;
  transition: .4s;
}
.mew-collapse .ant-collapse-item.ant-collapse-item-active .ant-collapse-header::after{
  transform: rotate(180deg);
}
.mew-collapse .ant-collapse-item .ant-collapse-content{
  border: none;
}
.mew-collapse .ant-collapse-item  .ant-collapse-content > .ant-collapse-content-box{
  padding: 16px 0;
  padding-bottom: 0;
}
