.mew-auth-input-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
}

.mew-auth-form-header h2 {
    color: var(--brand-one);
}

.mew-auth-form-header h6 {
    color: var(--grey-text);
    font-weight: 400;
    margin-bottom: 24px;
    margin-top: 0;
}

.mew-auth-input-container.mew-inputCol-2 .mew-input-container {
    width: calc(50% - 10px);
}