.mew-country-selector {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
}

.mew-input-container span.mew-select-left-icon {
    height: 100%;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0px;
    z-index: 10;
}

.mew-input-container .mew-left-icon .ReactFlagsSelect-module_selectBtn__19wW7 {
    padding-left: 38px;
}

.mew-country-select-container {
    width: 100%;
    height: 42px;
}

.mew-flag-img {
    position: absolute;
    left: 10px;
    z-index: 1000;
   
}
